<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base" v-if="is_filter">
      <vx-card title="Filter">
        <div class="vx-row mb-3">
          <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-3/4 w-full">
            <browse-company class="w-full" name="company_id" label="Company" v-model="filterData.company_id" @input="changeCompany"></browse-company>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-3/4 w-full">
            <browse-route class="w-full" name="route_id" label="Route" v-model="filterData.route_id" @input="changeRoute"></browse-route>
          </div>
        </div>
        <div class="vx-col w-full flex mt-3">
          <vs-button color="warning" @click="resetFilter()">Reset</vs-button>
        </div>
      </vx-card>
    </div>
    <div class="vx-col w-full mb-base">
      <vx-card title="Packing Lists / Manifest">
        <vs-table :sst="true" search @search="handleSearch" :data="data.data" @sort="handleSort" class="mb-3" >
          <template slot="header">
            <vs-button
              color="success"
              class="mr-1 ml-1"
              @click="is_filter = !is_filter"
              >Filter</vs-button
            >&nbsp;
            <vs-button
              color="primary"
              :to="{
                name: 'manifests-create',
                query: {
                  job_order_id: this.job_order_id,
                  delivery_order_id: this.delivery_order_id,
                },
              }"
              >Add New</vs-button
            >
          </template>
          <template slot="thead">
            <vs-th sort-key="name">Packing List No.</vs-th>
            <vs-th sort-key="route">Company</vs-th>
            <vs-th sort-key="route">Route</vs-th>
            <vs-th sort-key="vehicle_type">ETD</vs-th>
            <vs-th sort-key="price_tonase">ETA</vs-th>
            <vs-th sort-key="created_at"></vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.code">
                <router-link :to="{name:'manifests-show', params:{id: tr.id}}">{{tr.code}}</router-link>
              </vs-td>
              <vs-td  label='company' :data="tr.route">{{tr.company_name}}</vs-td>
              <vs-td  label='route' :data="tr.route">{{tr.route}}</vs-td>
              <vs-td  label='ETD' :data="tr.etd">{{tr.etd}}</vs-td>
              <vs-td  label='ETA' :data="tr.eta">{{tr.eta}}</vs-td>
              <vs-td :data="tr.id" width="10">
                <div class="flex">
                  <vs-button
                    size="small"
                    :to="{ name: 'manifests-edit', params: { id: tr.id } }"
                    type="line"
                    color="success"
                    icon-pack="feather"
                    icon="icon-edit"
                  ></vs-button>
                  <vs-button
                    size="small"
                    type="line"
                    :to="{ name: 'manifests-show', params: { id: tr.id } }"
                    icon-pack="feather"
                    icon="icon-folder"
                  ></vs-button>
                  <vs-button
                    v-show="job_order_id"
                    @click="unpackingConfirmation(tr.id, job_order_id)"
                    size="small"
                    type="line"
                    icon-pack="feather"
                    icon="icon-trash"
                    color="danger"
                  ></vs-button>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination :total="data.last_page" v-model="currentPage" class="mt-5"></vs-pagination>
      </vx-card>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import BrowseCompany from '@browse/Company.vue'
import BrowseRoute from '@browse/Route.vue'

export default {
  props : ['job_order_id', 'delivery_order_id'],
  components:{
    BrowseCompany,
    BrowseRoute,
  },
  data(){
    return {
      filterData : {},
      is_filter : false,
      idDelete: null,
      currentPage: 1,
      search: null,
      sortKey: null,
      sortVal: null,
    };
  },
  watch:{
    search(){
      this.currentPage=1
      this.getData()
    },
    currentPage() {
      this.getData();
    },
  },
  computed:{
    ...mapState({
      data: (state) => state.manifests.rows,
    }),
  },
  methods:{
    ...mapActions({
      dispatchIndex: "manifests/index",
      dispatchUnpacking: "manifests/unpacking",
      dispatchDestroy: "manifests/destroy",
    }),
    async getData(){
      let payload = {
        delivery_order_id: this.delivery_order_id,
        job_order_id: this.job_order_id,
        page: this.currentPage,
        search: this.search,
        sortKey: this.sortKey,
        sortVal: this.sortVal,
      };
      payload = Object.assign(payload, this.filterData);
      await this.dispatchIndex(payload);
    },
    async confirmDelete() {
      try {
        await this.dispatchDestroy(this.idDelete);
        this.getData();
        this.$vs.notify({
          title: `Success`,
          text: `Your data has been deleted successfully`,
          color: `primary`,
        });
      } catch (error) {
        this.$vs.notify({
          title: `Oops!`,
          text: `Looks like something went wrong. please try again later (${error.data.message})`,
          color: `danger`,
        });
      }
      // payload = Object.assign(payload, this.filterData)
      // await this.dispatchIndex(payload)
    },
    async confirmUnpacking() {
      // let params = {
      //   manifest: this.manifestId,
      //   jobOrder: this.jobOrderId,
      // };
      try {
        await this.dispatchDestroy(this.idDelete)
        this.getData()
        this.$vs.notify({
          title: `Success`,
          text: `This maniffest detail has been unpacked successfully`,
          color: `primary`,
        });
      } catch (error) {
        this.$vs.notify({
          title: `Oops!`,
          text: `Looks like something went wrong. please try again later (${error.data.message})`,
          color: `danger`,
        });
      }
    },
    deletes(id){
      this.idDelete = id
      this.$vs.dialog({
        type: `confirm`,
        color: `danger`,
        title: `Are you sure ?`,
        text: `Deleted data can no longer be restored`,
        accept: this.confirmDelete,
      });
    },
    unpackingConfirmation(manifest, jobOrder) {
      this.manifestId = manifest;
      this.jobOrderId = jobOrder;
      this.$vs.dialog({
        type: `confirm`,
        color: `danger`,
        title: `Are you sure to unpack this manifest detail ?`,
        text: `Deleted data can no longer be restored`,
        accept: this.confirmUnpacking,
      });
    },
    handleSearch(val){
      this.search = val
    },
    handleSort(key,val){
      this.sortKey = key
      this.sortVal = val
      this.getData()
    },
    async changeCompany(data){
      this.filterData.company_id = data
      await this.getData();
    },
    async changeRoute(data){
      this.filterData.route_id = data
      await this.getData();
    },
    async resetFilter() {
      this.filterData = {};
      await this.getData();
    },
  },
  async mounted() {
    await this.getData();
  },
};
</script>

<style>

</style>