var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vx-row"},[(_vm.is_filter)?_c('div',{staticClass:"vx-col w-full mb-base"},[_c('vx-card',{attrs:{"title":"Filter"}},[_c('div',{staticClass:"vx-row mb-3"},[_c('div',{staticClass:"vx-col lg:w-1/3 md:w-1/2 sm:w-3/4 w-full"},[_c('browse-company',{staticClass:"w-full",attrs:{"name":"company_id","label":"Company"},on:{"input":_vm.changeCompany},model:{value:(_vm.filterData.company_id),callback:function ($$v) {_vm.$set(_vm.filterData, "company_id", $$v)},expression:"filterData.company_id"}})],1)]),_c('div',{staticClass:"vx-row mb-3"},[_c('div',{staticClass:"vx-col lg:w-1/3 md:w-1/2 sm:w-3/4 w-full"},[_c('browse-route',{staticClass:"w-full",attrs:{"name":"route_id","label":"Route"},on:{"input":_vm.changeRoute},model:{value:(_vm.filterData.route_id),callback:function ($$v) {_vm.$set(_vm.filterData, "route_id", $$v)},expression:"filterData.route_id"}})],1)]),_c('div',{staticClass:"vx-col w-full flex mt-3"},[_c('vs-button',{attrs:{"color":"warning"},on:{"click":function($event){return _vm.resetFilter()}}},[_vm._v("Reset")])],1)])],1):_vm._e(),_c('div',{staticClass:"vx-col w-full mb-base"},[_c('vx-card',{attrs:{"title":"Packing Lists / Manifest"}},[_c('vs-table',{staticClass:"mb-3",attrs:{"sst":true,"search":"","data":_vm.data.data},on:{"search":_vm.handleSearch,"sort":_vm.handleSort},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr},[_c('vs-td',{attrs:{"data":tr.code}},[_c('router-link',{attrs:{"to":{name:'manifests-show', params:{id: tr.id}}}},[_vm._v(_vm._s(tr.code))])],1),_c('vs-td',{attrs:{"label":"company","data":tr.route}},[_vm._v(_vm._s(tr.company_name))]),_c('vs-td',{attrs:{"label":"route","data":tr.route}},[_vm._v(_vm._s(tr.route))]),_c('vs-td',{attrs:{"label":"ETD","data":tr.etd}},[_vm._v(_vm._s(tr.etd))]),_c('vs-td',{attrs:{"label":"ETA","data":tr.eta}},[_vm._v(_vm._s(tr.eta))]),_c('vs-td',{attrs:{"data":tr.id,"width":"10"}},[_c('div',{staticClass:"flex"},[_c('vs-button',{attrs:{"size":"small","to":{ name: 'manifests-edit', params: { id: tr.id } },"type":"line","color":"success","icon-pack":"feather","icon":"icon-edit"}}),_c('vs-button',{attrs:{"size":"small","type":"line","to":{ name: 'manifests-show', params: { id: tr.id } },"icon-pack":"feather","icon":"icon-folder"}}),_c('vs-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.job_order_id),expression:"job_order_id"}],attrs:{"size":"small","type":"line","icon-pack":"feather","icon":"icon-trash","color":"danger"},on:{"click":function($event){return _vm.unpackingConfirmation(tr.id, _vm.job_order_id)}}})],1)])],1)})}}])},[_c('template',{slot:"header"},[_c('vs-button',{staticClass:"mr-1 ml-1",attrs:{"color":"success"},on:{"click":function($event){_vm.is_filter = !_vm.is_filter}}},[_vm._v("Filter")]),_vm._v(" "),_c('vs-button',{attrs:{"color":"primary","to":{
              name: 'manifests-create',
              query: {
                job_order_id: this.job_order_id,
                delivery_order_id: this.delivery_order_id,
              },
            }}},[_vm._v("Add New")])],1),_c('template',{slot:"thead"},[_c('vs-th',{attrs:{"sort-key":"name"}},[_vm._v("Packing List No.")]),_c('vs-th',{attrs:{"sort-key":"route"}},[_vm._v("Company")]),_c('vs-th',{attrs:{"sort-key":"route"}},[_vm._v("Route")]),_c('vs-th',{attrs:{"sort-key":"vehicle_type"}},[_vm._v("ETD")]),_c('vs-th',{attrs:{"sort-key":"price_tonase"}},[_vm._v("ETA")]),_c('vs-th',{attrs:{"sort-key":"created_at"}})],1)],2),_c('vs-pagination',{staticClass:"mt-5",attrs:{"total":_vm.data.last_page},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }